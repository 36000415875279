import React, { useEffect, useState } from 'react';
import MobileGameCard from 'modules/Room/mobile/MobileGameCard/MobileGameCard';
import MobileRoomMenu from 'modules/Room/mobile/MobileRoomMenu/MobileRoomMenu';
import MobilePlayMenu from 'modules/Room/mobile/MobilePlayMenu/MobilePlayMenu';
import { useParams } from 'react-router-dom';
import { useRoomStore } from 'shared/store/roomStore';
import useCreateJoinRoomEvents from 'modules/Room/Hooks/useCreateJoinRoomEvents';
import MobileHistoryMoves from 'modules/Room/mobile/MobileHistoryMoves/MobileHistoryMoves';
import victory from 'assets/png/victory.png';
import defeat from 'assets/png/defeat.png';
import {
  RoomStatusEnum,
  WinnerStatusEnum,
} from 'shared/constants/shared-enums';
import { NULL_ADDRESS } from 'shared/constants/constants';
import AnimatedTransition from 'shared/components/AnimatedTransition/AnimatedTransition';
import { useModalStore } from 'modules/Room/RoomModals/store/modalStore';
import GameFinishViews from 'modules/Room/features/GameFinishViews/GameFinishViews';
import { Hash } from 'modules/Room/features/Hash/Hash';
import floor from 'assets/png/floor.png';
import mobHumanLeft from 'assets/png/mob-human-left.png';
import mobHumanRight from 'assets/png/mob-human-right.png';
import classNames from 'classnames';

const MobileRoom = () => {
  const [showMoves, setShowMoves] = useState<boolean>(false);
  const { closeAllModals } = useModalStore();
  const { showHash, setShowHash } = useRoomStore();
  const {
    bet,
    playerB,
    someoneElseGame,
    winnerStatus,
    amIPlayerA,
    roomStatus,
  } = useRoomStore();
  const { activeRoomId } = useParams();
  useEffect(() => {
    if (
      activeRoomId &&
      playerB.address !== NULL_ADDRESS &&
      winnerStatus === WinnerStatusEnum.None &&
      roomStatus !== RoomStatusEnum.Closed &&
      !someoneElseGame
    ) {
      setShowMoves(true);
    } else {
      setShowMoves(false);
    }
  }, [activeRoomId, playerB, winnerStatus]);
  useEffect(() => {
    const style = document.createElement('style');
    style.innerHTML = `
      #sentry-feedback {
        --inset: auto auto 0 0;
      }
    `;
    const sentry = document.querySelector('#sentry-feedback');
    let timeoutId: NodeJS.Timeout;
    if (sentry) {
      const shadowRoot = sentry.shadowRoot;
      if (shadowRoot) {
        const tooltip = document.createElement('span');
        tooltip.textContent = 'Report a Bug';
        tooltip.style.backgroundColor = '#0C0B4D';
        tooltip.style.color = '#F7F7F7';
        tooltip.style.padding = '7px';
        tooltip.style.borderRadius = '4px';
        tooltip.style.border = '1px solid #202066';
        tooltip.style.zIndex = '1000';
        tooltip.style.position = 'absolute';
        tooltip.style.bottom = '76px';
        tooltip.style.left = '6px';
        tooltip.style.fontFamily = 'Poppins, sans-serif';
        shadowRoot.appendChild(tooltip);
        timeoutId = setTimeout(() => {
          shadowRoot.removeChild(tooltip);
        }, 5000);
        document.head.appendChild(style);

        return () => {
          if (timeoutId) {
            clearTimeout(timeoutId);
          }
          if (shadowRoot && shadowRoot.contains(tooltip)) {
            shadowRoot.removeChild(tooltip);
          }
          document.head.removeChild(style);
        };
      }
    }
  }, []);
  useCreateJoinRoomEvents();
  const [animationsTriggered, setAnimationsTriggered] = useState(false);
  useEffect(() => {
    if (
      activeRoomId &&
      playerB.address !== NULL_ADDRESS &&
      roomStatus !== RoomStatusEnum.Closed
    ) {
      setAnimationsTriggered(true);
    }
  }, [activeRoomId, playerB]);
  const [isShowEndGameAnimation, setIsShowEndGameAnimation] =
    useState<boolean>(false);
  useEffect(() => {
    if (roomStatus === RoomStatusEnum.Closed && !someoneElseGame) {
      closeAllModals();
      setIsShowEndGameAnimation(true);
    }
  }, [roomStatus]);
  const renderGameResultImage = () => {
    if (someoneElseGame || !isShowEndGameAnimation) {
      return null;
    }
    setTimeout(() => {
      setIsShowEndGameAnimation(false);
    }, 6000);

    if (!amIPlayerA) {
      if (winnerStatus === WinnerStatusEnum.WinnerPlayerB) {
        return (
          <img
            src={victory}
            alt="victory image"
            className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 animate-fadeIn`}
          />
        );
      }

      if (winnerStatus === WinnerStatusEnum.WinnerPlayerA) {
        return (
          <img
            src={defeat}
            alt="defeat image"
            className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 `}
          />
        );
      }
    }

    if (winnerStatus === WinnerStatusEnum.WinnerPlayerA) {
      return (
        <img
          src={victory}
          alt="victory image"
          className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2`}
        />
      );
    }

    if (winnerStatus === WinnerStatusEnum.WinnerPlayerB) {
      return (
        <img
          src={defeat}
          alt="defeat image"
          className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 `}
        />
      );
    }

    return null;
  };

  return (
    <div className="w-full h-full relative overflow-x-hidden">
      <MobileRoomMenu />
      <div className="h-screen min-h-[570px] max-h-full flex flex-col justify-between">
        <div className="pt-[12px] sm:pt-[30px] px-[14px] watch-game-card player-card-host">
          <div className="h-[44px] mb-[12px]" />

          <MobileGameCard />
          {!isShowEndGameAnimation && <MobileHistoryMoves />}
        </div>
        {showMoves && <MobilePlayMenu />}
        <AnimatedTransition show={isShowEndGameAnimation}>
          {renderGameResultImage()}
        </AnimatedTransition>
        <AnimatedTransition show={showHash}>
          <div className="border-[2px] sm:border-[3px] border-border rounded-[4px] sm:rounded-[14px] w-[90%] mx-auto max-w-[500px] h-[450px] overflow-hidden radi h-content absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <Hash setIsShow={setShowHash} />
          </div>
        </AnimatedTransition>
        <AnimatedTransition
          show={
            roomStatus === RoomStatusEnum.Closed &&
            !someoneElseGame &&
            !isShowEndGameAnimation
          }
        >
          <GameFinishViews />
        </AnimatedTransition>
      </div>
      <img
        src={mobHumanLeft}
        alt="guy"
        className={classNames(
          'absolute bottom-10 left-1/2 transform -translate-x-full z-[100] h-[500px] opacity-0',
          animationsTriggered && 'animate-slide-in-from-left-mobile'
        )}
      />
      <img
        src={mobHumanRight}
        alt="guy"
        className={classNames(
          'absolute bottom-10 right-1/2 transform translate-x-full z-[100] h-[500px] opacity-0',
          animationsTriggered && 'animate-slide-in-from-right-mobile'
        )}
      />
      <img
        src={floor}
        alt="floor"
        className="absolute bottom-0 left-1/2 transform -translate-x-1/2 z-0 h-[20%] min-w-[1120px]"
      />
    </div>
  );
};

export default MobileRoom;
