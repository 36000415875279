import React, { forwardRef } from 'react';
import warning from 'assets/png/warning.png';
import { InputHash } from './InputHash';
import { usePlayerGamesStore } from 'shared/store/playerGamesStore';
import { useAccount } from 'wagmi';
import { useParams } from 'react-router-dom';
import ArrowDown from 'assets/arrow-down.svg?react';
import { useTranslation } from 'react-i18next';

interface Props {
  setIsShow: (value: boolean) => void;
}

export const Hash = forwardRef<HTMLDivElement, Props>(({ setIsShow }, ref) => {
  const { mainSalt } = usePlayerGamesStore();
  const { address, chainId } = useAccount();
  const { activeRoomId } = useParams();
  const { t } = useTranslation();
  return (
    <div
      ref={ref}
      className="absolute top-0 bottom-0 left-0 right-0 z-[100] bg-dark-blue w-full h-full flex flex-col items-center justify-between"
    >
      <div
        className="absolute w-[613px] top-0 h-[100px] xl:h-[200px]"
        style={{
          background: 'rgba(255, 167, 0, 0.25)',
          filter: 'blur(64px)',
        }}
      />
      <img
        src={warning}
        alt="warning"
        className="w-[30%] z-[1000] xl:w-[35%] mt-2 hd:mt-10"
      />

      <div className="text-white text-[12px] sm:text-[14px] xl:text-[16px] text-center w-[90%]">
        <p className="text-link opacity-80 text-[16px] sm:text-[20px] xl:text-[24px] font-bold hd:mb-2">
          {t('hash.attention')}
        </p>
        <p className="mb-2 opacity-80">{t('hash.interrupted')}</p>
        <p className="opacity-80">{t('hash.need')}</p>
      </div>
      <div className="w-full flex flex-col items-center">
        <InputHash
          className="w-[90%] hd:mb-2"
          value={mainSalt[activeRoomId! + address! + chainId] ?? ''}
          copy={!!mainSalt[activeRoomId! + address! + chainId]}
        />
        <button
          onClick={() => setIsShow(false)}
          className="mb-2 flex opacity-60 items-center text-[14px] sm:text-[16px] hd:text-[20px] text-white"
        >
          {t('hash.hide')} <ArrowDown className="ml-2" />
        </button>
      </div>
    </div>
  );
});
