import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MovesEnum } from 'shared/constants/shared-enums';
import stone from 'assets/png/stone.png';
import paper from 'assets/png/paper.png';
import scissors from 'assets/png/scissors.png';
import MoveIcon from 'shared/components/MoveIcon/MoveIcon';
import './RockPaperScissors.css';
import AnimatedTransition from 'shared/components/AnimatedTransition/AnimatedTransition';

interface Props {
  move: MovesEnum;
  selectMove: (move: MovesEnum) => void;
}

export const RockPaperScissors = React.forwardRef<HTMLDivElement, Props>(
  ({ move, selectMove }, ref) => {
    const { t } = useTranslation();

    return (
      <div ref={ref} className="flex justify-evenly w-[219px] sm:w-full px-0 sm:px-1">
        <span />
        <MoveIcon
          clickHandler={() => selectMove(MovesEnum.Rock)}
          active={move === MovesEnum.Rock}
          disabledHover={!!move}
          hide={!!move && move !== MovesEnum.Rock}
          imgSrc={stone}
          alt={t('common.rock')}
          text={t('common.rock')}
        />
        <MoveIcon
          clickHandler={() => selectMove(MovesEnum.Paper)}
          active={move === MovesEnum.Paper}
          disabledHover={!!move}
          hide={!!move && move !== MovesEnum.Paper}
          imgSrc={paper}
          alt={t('common.paper')}
          text={t('common.paper')}
        />
        <MoveIcon
          clickHandler={() => selectMove(MovesEnum.Scissors)}
          active={move === MovesEnum.Scissors}
          disabledHover={!!move}
          hide={!!move && move !== MovesEnum.Scissors}
          imgSrc={scissors}
          alt={t('common.scissors')}
          text={t('common.scissors')}
        />
      </div>
    );
  }
);
