import { createRoot } from 'react-dom/client';
import { StrictMode } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import 'shared/i18n/i18n';
import { Web3WagmiConfig } from 'shared/web3/components/web3-wagmi-config/Web3WagmiConfig';
import App from './app/App';
import '@rainbow-me/rainbowkit/styles.css';
import * as Sentry from '@sentry/react';
import ReactPixel from 'react-facebook-pixel';

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);

Sentry.init({
  dsn: 'https://46f1484259c883ef3f5a12f03ce40887@o4507333642158080.ingest.de.sentry.io/4507333708873808',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.feedbackIntegration({
      showBranding: false,
      showName: false,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
    // Sentry.captureConsoleIntegration()
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  denyUrls: ['localhost'],
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const pixelId = '1494392224593363';
ReactPixel.init(pixelId);
ReactPixel.pageView();

root.render(
  <StrictMode>
    <Web3WagmiConfig>
      <Router>
        <App />
      </Router>
    </Web3WagmiConfig>
  </StrictMode>
);
