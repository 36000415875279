import React from 'react';
import ModalsConnectCreate from './components/ModalsConnectCreate/ModalsConnectCreate';
import ModalsPlayGame from './components/ModalsPlayGame/ModalsPlayGame';
import LiveGamesModal from './components/ModalsConnectCreate/modals/LiveGamesModal/LiveGamesModal';
import { useModalStore } from './store/modalStore';
import { ModalTypeEnum } from './constants/modalEnums';

const RoomModals = () => {
  const { modalState } = useModalStore();

  return (
    <>
      <ModalsConnectCreate />
      <ModalsPlayGame />
      {modalState[ModalTypeEnum.SET_BET] ? <LiveGamesModal /> : null}
    </>
  );
};

export default RoomModals;
