import { CurrencyType } from "../web3";

export interface PlayerType {
  address: `0x${string}`;
  wins: bigint;
}

export interface RoomInfo {
  data: {
    roomId: bigint;
    playerA: string;
    playerB: string;
    wins: number;
    token: string;
    bet: bigint;
  };
  pot: bigint;
  roundCounter: number;
  winsCounter: { winsPlayerA: number; winsPlayerB: number };
  closer: number;
  deadline: bigint;
  status: number;
  winnerStatus: number;
}

export interface WriteError {
  cause?: {
    code?: number;
  };
}

export enum RoomStatus {
  ROOM_CLOSED = 'RoomClosed',
  ROOM_JOINED = 'RoomJoined',
  ROOM_CREATED = 'RoomCreated'
}

export interface ActiveRoom {
  playerAddress: string;
  currency: CurrencyType | undefined;
  amount: number;
  roomId: BigInt;
  status?: RoomStatus;
}
