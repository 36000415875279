import { RANGE_OF_GAME_REQUEST } from "@/shared/constants/constants";
import {
  useReadRspContractActiveRoomCounter,
  useReadRspContractGetRoomsInfo,
  useReadRspContractRoomIdCounter,
 } from "@/shared/features/contract.abi";
import { useCurrenciesStore } from "@/shared/store/currenciesStore";
import { useEffect, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import ClockIcon from 'assets/clock.svg?react';
import CurrencyIcon from 'assets/currency.svg?react';
import FilterIcon from 'assets/filter.svg?react';
import ClockSmallIcon from 'assets/clock-small.svg?react';
import CurrencySmallIcon from 'assets/currency-small.svg?react';
import FilterSmallIcon from 'assets/filter-small.svg?react';
import Dropdown from "@/shared/components/DropDown/DropDown";
import ArrowIcon from 'assets/arrow.svg?react';
import { ActiveRoom, RoomInfo } from "@/shared/types/app-types";
import { RoomStatusEnum, WinnerStatusEnum } from "@/shared/constants/shared-enums";
import { useAccount } from "wagmi";
import { useBlockToFindCreateAndJoin } from 'modules/Room/Hooks/useBlockToFindCreateAndJoin';
import { LiveGameItem } from "./LiveGameItem";
import useMediaQuery from "@/shared/hooks/useMediaQuery";
import AnimatedTransition from "@/shared/components/AnimatedTransition/AnimatedTransition";
import { useModalStore } from "@/modules/Room/RoomModals/store/modalStore";
import { ModalTypeEnum } from "@/modules/Room/RoomModals/constants/modalEnums";

interface SortingConfig {
  key: 'roomId' | 'amount',
  order: 'asc' | 'desc',
}

const tableTitleStyles = 'text-[#F7F7F780] text-[9px] sm:text-[20px] font-medium flex gap-2 items-center'
const tableSubTitleStyles = 'text-[#F7F7F7BF] text-[10px] sm:text-[20px] font-semibold'
const tableColumnStyles = 'flex flex-col'
const modalStyles = 'absolute border-[2px] sm:border-[3px] border-border rounded-[4px] sm:rounded-[14px] z-40 transform -translate-y-1/2 sm:min-w-min modal__right z-50 w-full max-w-[674px] px-[24px] py-[20px] games-modal-container games-modal-background'
const modalMobileStyles = 'absolute border-[2px] sm:border-[3px] border-border rounded-[4px] sm:rounded-[14px] z-40 z-50 w-[95%] p-[10px] games-modal-container games-modal-background top-[570px] sm:top-[620px] m-[2.5%]'
const modalContainerStyles = 'grid grid-cols-12 gap-8 relative'
const modalMobileTitleStyles = 'font-bold text-[28px] top-[-70px] absolute gradient-title'
const modalCurrencyFilterStyles = 'text-white/75 flex gap-2 items-center text-[10px] sm:text-[20px] relative'
const modalRoomsListStyles = 'flex flex-col gap-2 sm:gap-4 h-[492px] overflow-scroll mt-[20px] games-list overflow-x-hidden'
const modalDesktopContainerStyles = 'positioning-anchor absolute z-[10000] h-[20px] top-1/2 w-full max-w-[1800px] transform -translate-y-1/2'
const modalTitleStyles = 'font-bold text-[52px] top-[-120px] absolute gradient-title'

const LiveGamesModal = () => {
  const [currencyFilter, setCurrencyFilter] = useState<string>('All');
  const [isCurrencyFilterOpen, setIsCurrencyFilterOpen] = useState(false);
  const { address } = useAccount();
  const [activeRooms, setActiveRooms] = useState<RoomInfo[]>([]);
  const [parsedActiveRooms, setParsedActiveRooms] = useState<ActiveRoom[]>([]);
  const [sortingConfig, setSortingConfig] = useState<SortingConfig>({ key: 'roomId', order: 'desc' });
  const hasNavigatedRef = useRef(false);
  const [isJoinRoomGlobalLoading, setIsJoinRoomGlobalLoading] = useState(false);
  const { setModalState } = useModalStore();
  const isTablet = useMediaQuery('(max-width: 1400px)');
  const {
    availableCurrencies,
    currencyForSelect,
  } = useCurrenciesStore();

  // Set active rooms logic
  const [roomIds, setRoomIds] = useState({
    fromRoomId: 0n,
    toRoomId: 0n,
  });
  const { data: lastRoomId, isSuccess: lastRoomIdSuccess } =
    useReadRspContractRoomIdCounter({
      query: {
        staleTime: 0,
      },
    });
  const {
    data: roomsInfo,
    isSuccess: roomsInfoSuccess,
  } = useReadRspContractGetRoomsInfo({
    args: [roomIds.fromRoomId, roomIds.toRoomId],
    query: {
      enabled: roomIds.toRoomId !== 0n,
      staleTime: 0,
      refetchOnMount: true,
    },
  });
  const { data: activeRoomCounter, isSuccess: activeRoomCounterSuccess } =
    useReadRspContractActiveRoomCounter({
      query: {
        staleTime: 0,
        refetchOnMount: true,
        refetchOnWindowFocus: true,
      },
    });

  useEffect(() => {
    if (
      activeRoomCounterSuccess &&
      activeRoomCounter > 0n &&
      roomsInfoSuccess &&
      !hasNavigatedRef.current &&
      address
    ) {
      const activeRoom = activeRooms.findLast((item) => {
        return (
          [
            item.data.playerA.toLowerCase(),
            item.data.playerB.toLowerCase(),
          ].includes(address.toLowerCase()) &&
          item.status !== RoomStatusEnum.Closed &&
          item.winnerStatus === WinnerStatusEnum.None
        );
      });
      if (activeRooms.length < Number(activeRoomCounter) && !activeRoom) {
        setRoomIds({
          fromRoomId:
            roomIds.fromRoomId - BigInt(RANGE_OF_GAME_REQUEST) < 0n
              ? 0n
              : roomIds.fromRoomId - BigInt(RANGE_OF_GAME_REQUEST),
          toRoomId: roomIds.fromRoomId,
        });
      }
    }
  }, [
    activeRooms,
    activeRoomCounter,
    roomsInfoSuccess,
    activeRoomCounterSuccess,
  ]);
  useEffect(() => {
    if (lastRoomId && lastRoomIdSuccess && !hasNavigatedRef.current) {
      setRoomIds({
        fromRoomId:
          lastRoomId - BigInt(RANGE_OF_GAME_REQUEST) < 0n
            ? 0n
            : lastRoomId - BigInt(RANGE_OF_GAME_REQUEST),
        toRoomId: lastRoomId ?? 0n,
      });
    }
  }, [lastRoomId, lastRoomIdSuccess]);
  useEffect(() => {
    if (roomsInfo && roomsInfoSuccess && !hasNavigatedRef.current) {
      const uniqueRooms =  Array.from(
        new Map([...activeRooms,
          ...roomsInfo.filter(
            (room: any) =>
              room.status !== RoomStatusEnum.Closed && room.data.roomId !== 0n
          )].map(item => [item.data.roomId, item])).values()
      );

      setActiveRooms(uniqueRooms);
    }
  }, [roomsInfo, roomsInfoSuccess]);
  useEffect(() => {
    const newParsedActiveRooms = activeRooms?.map((item) => {
      const roomCurrency = availableCurrencies.find((currency) => currency.address.toLowerCase() === item.data.token.toLowerCase());

      if (!roomCurrency) {
        return null
      }

      const roomBet = Number(item.data.bet) / 10 ** roomCurrency.decimals;

      return {
        playerAddress: item.data.playerA,
        currency: roomCurrency,
        amount: roomBet,
        roomId: item.data.roomId,
      }
    }).filter((item) => !!item);

    setParsedActiveRooms(newParsedActiveRooms);
  }, [activeRooms])
  
  // Update active rooms logic
  const { roomsToRemove, roomsToAdd } = useBlockToFindCreateAndJoin();

  useEffect(() => {
    if (roomsToRemove.length) {
      const deleteIds = new Set(roomsToRemove.map(item => item.roomId));

      if (!deleteIds.size) {
        return;
      }
      
      const updatedRooms = parsedActiveRooms.filter(activeRoom => !deleteIds.has(activeRoom.roomId));

      setParsedActiveRooms(updatedRooms);
    }
  }, [roomsToRemove])

  useEffect(() => {
    if (roomsToAdd.length) {
      const existingRoomIds = new Set(parsedActiveRooms.map(room => room.roomId));
  
      const newRooms = roomsToAdd.filter(
        room => !existingRoomIds.has(room.roomId)
      );      
      setParsedActiveRooms([...newRooms, ...parsedActiveRooms]);
    }
  }, [roomsToAdd])


  const handleToggleCurrencyFilter = () => {
    setIsCurrencyFilterOpen(!isCurrencyFilterOpen);
  }

  const handleChangeCurrencyFilter = (value: string) => {
    setCurrencyFilter(value)
  }

  const handleResetRooms = () => {
    setModalState(ModalTypeEnum.SET_BET, false);
  }

  const handleSetIsJoinRoomGlobalLoading = (value: boolean) => {
    setIsJoinRoomGlobalLoading(value)
  }

  const handleSort = (key: 'roomId' | 'amount') => {
    setSortingConfig(prevConfig => ({
      key,
      order: 
        prevConfig.key === key && prevConfig.order === 'asc'
          ? 'desc'
          : 'asc'
    }));
  };

  const sortedRooms = useMemo(() => {
    let sortableRooms = [...parsedActiveRooms];
    
    sortableRooms.sort((a, b) => {
      if (a[sortingConfig.key] < b[sortingConfig.key]) {
        return sortingConfig.order === 'asc' ? -1 : 1;
      }
      if (a[sortingConfig.key] > b[sortingConfig.key]) {
        return sortingConfig.order === 'asc' ? 1 : -1;
      }
      return 0;
    });

    return sortableRooms;
  }, [parsedActiveRooms, sortingConfig]);
  

  if (!sortedRooms?.length) {
    return null;  
  }

  if (isTablet) {
    return createPortal(
      <AnimatedTransition show={true}>
        <div className={modalMobileStyles}>
          <div className={modalContainerStyles}>
            <div className={modalMobileTitleStyles}>
              Open Rooms
            </div>
            <div className={`${tableColumnStyles} col-span-5`}>
              <span className={`${tableTitleStyles} cursor-pointer`} onClick={() => handleSort('roomId')}>Date created <ClockSmallIcon /></span>
              <span className={tableSubTitleStyles}>
                {sortingConfig.key === 'roomId' ? 
                (sortingConfig.order === 'asc' ? 'Oldest Games' : 'Newest Games')
                : 'Newest Games'}
              </span>            </div>
            <div className={`${tableColumnStyles} col-span-3`}>
              <span className={tableTitleStyles}>Currency <CurrencySmallIcon /></span>
              <div onClick={handleToggleCurrencyFilter} className={modalCurrencyFilterStyles}>
                {currencyFilter}
                <ArrowIcon
                  className={`${
                    isCurrencyFilterOpen ? 'transform rotate-180' : ''
                  } transition ease-in-out duration-500`}
                />

                <Dropdown
                  open={isCurrencyFilterOpen}
                  list={[ 
                    { value: 'All', label: 'All' },
                    ...currencyForSelect
                  ]}
                  onClick={(option) => handleChangeCurrencyFilter(option.label)}
                  active={currencyFilter}
                  position={{ top: 30, left: 0 }}
                />
              </div>
            </div>
            <div className={`${tableColumnStyles} col-span-4`}>
              <span className={`${tableTitleStyles} cursor-pointer`} onClick={() => handleSort('amount')}>Bet <FilterSmallIcon /></span>
              <span className={tableSubTitleStyles}>
                {sortingConfig.key === 'amount' ? 
                (sortingConfig.order === 'asc' ? 'Low to High' : 'High to Low')
              : 'High to Low'}
              </span>
            </div>
          </div>
          <div className={modalRoomsListStyles}>
            {sortedRooms?.filter((item) => currencyFilter === 'All' ? true : item.currency?.symbol === currencyFilter).map((item) => (
              <LiveGameItem 
                key={`${item.roomId.toString()}_${item.playerAddress}`}
                roomId={item.roomId}
                address={item.playerAddress}
                amount={item.amount}
                currency={item.currency}
                onJoinRoomCallback={handleResetRooms}
                isJoinRoomGlobalLoading={isJoinRoomGlobalLoading}
                onSetIsJoinRoomGlobalLoading={handleSetIsJoinRoomGlobalLoading}
              />
            ))}
          </div>
        </div>
      </AnimatedTransition>,
      document.body
    )
  }
  
  return createPortal(
    <AnimatedTransition show={true}>
      <div className={modalDesktopContainerStyles}>
        <div className={modalStyles}>
          <div className={modalContainerStyles}>
            <div className={modalTitleStyles}>
              Open Rooms
            </div>
            <div className={`${tableColumnStyles} col-span-5`}>
              <span className={`${tableTitleStyles} cursor-pointer`} onClick={() => handleSort('roomId')}>Date created <ClockIcon /></span>
              <span className={tableSubTitleStyles}>
                {sortingConfig.key === 'roomId' ? 
                (sortingConfig.order === 'asc' ? 'Oldest Games' : 'Newest Games')
                : 'Newest Games'}
              </span>
            </div>
            <div className={`${tableColumnStyles} col-span-3`}>
              <span className={tableTitleStyles}>Currency <CurrencyIcon /></span>
              <div onClick={handleToggleCurrencyFilter} className={modalCurrencyFilterStyles}>
                {currencyFilter}
                <ArrowIcon
                  className={`${
                    isCurrencyFilterOpen ? 'transform rotate-180' : ''
                  } transition ease-in-out duration-500`}
                />

                <Dropdown
                  open={isCurrencyFilterOpen}
                  list={[ 
                    { value: 'All', label: 'All' },
                    ...currencyForSelect
                  ]}
                  onClick={(option) => handleChangeCurrencyFilter(option.label)}
                  active={currencyFilter}
                  position={{ top: 30, left: 0 }}
                />
              </div>
            </div>
            <div className={`${tableColumnStyles} col-span-4`}>
              <span className={`${tableTitleStyles} cursor-pointer`} onClick={() => handleSort('amount')}>Bet <FilterIcon /></span>
              <span className={tableSubTitleStyles}>
                {sortingConfig.key === 'amount' ? 
                (sortingConfig.order === 'asc' ? 'Low to High' : 'High to Low')
                : 'High lo Low'}
              </span>
            </div>
          </div>
          <div className={modalRoomsListStyles}>
            {sortedRooms?.filter((item) => currencyFilter === 'All' ? true : item.currency?.symbol === currencyFilter).map((item) => (
              <LiveGameItem
                key={`${item.roomId.toString()}_${item.playerAddress}`}
                roomId={item.roomId}
                address={item.playerAddress}
                amount={item.amount}
                currency={item.currency}
                onJoinRoomCallback={handleResetRooms}
                isJoinRoomGlobalLoading={isJoinRoomGlobalLoading}
                onSetIsJoinRoomGlobalLoading={handleSetIsJoinRoomGlobalLoading}
              />
            ))}
          </div>
        </div>
      </div>
    </AnimatedTransition>,
    document.body
  )
}

export default LiveGamesModal;
